import styled from 'styled-components';
import { purpGradStart, purpGradEnd } from 'constants/theme';

export const RedIndicator = styled.div`
  background: linear-gradient(90deg, ${purpGradStart}, ${purpGradEnd});

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 27px;
  text-align: center;
  padding: 1.55rem 4rem;
  margin-bottom: 4rem;
  letter-spacing: 0.1em;
  font-weight: 600;
  color: white;
  transition: box-shadow 0.2s ease-out;
  line-height: 1;
  /* white-space: nowrap; */
  box-sizing: border-box;
  text-transform: uppercase;

  .outer-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 16px; /* no longer auto */
    height: 16px;
    width: 16px;
    margin-right: 1rem;
    border-radius: 50%;
    background: transparent;
    border: 2px solid white;
  }
  .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: white;
  }
`;
